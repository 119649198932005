import React from "react"
import { useNavigate } from "react-router-dom"
import "./Timeline.css"

const TimelineHeader = () => {
  const navigate = useNavigate()

  const navigateToHome = () => {
    navigate("/")
  }

  return (
    <div className="vertical-timeline-navbar" onClick={navigateToHome}>
      <img src="images/logo_black.jpg" alt="Company logo" className="vertical-timeline-logo" />
    </div>
  )
}

export default TimelineHeader
