import React, { useState, useEffect } from "react"
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import "./Timeline.css"
import ParticlesBg from "particles-bg"
import TimelineHeader from "./TimelineHeader"

const Timeline = () => {
  const [timelineData, setTimelineData] = useState([])

  useEffect(() => {
    fetch(`/timedata.json?timestamp=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.timeline) && data.timeline.every((item) => item.year && Array.isArray(item.months))) {
          setTimelineData(data.timeline)
        } else {
          alert("The data structure is invalid. Please check the JSON file.")
        }
      })
      .catch((error) => console.error("Error loading timeline data:", error))
  }, [])

  const renderEventMessage = (event) => {
    const renderCompetitionDetails = (event) => (
      <>
        {Array.isArray(event.awards) &&
          event.awards.map((award, index) => (
            <div key={index}>
              <li>
                <strong>{award.team}</strong> {award.rank ? "won" : ""} {award.rank ? <u>{award.rank}</u> : award.desc} {award.awardName ? "in" : ""} <em>{award.awardName}</em>
              </li>
            </div>
          ))}
      </>
    )

    switch (event.type) {
      case "desc":
        return <div className="vertical-timeline-description">{event.desc}</div>
      case "competition":
        return <div className="vertical-timeline-event">{renderCompetitionDetails(event)}</div>
      default:
        return <div>Unknown event type</div>
    }
  }

  const YearSection = ({ yearData, yearIndex }) => (
    <div key={yearIndex} className="year-section">
      <div className="year-marker sticky-year">{yearData.year}</div>
      <VerticalTimeline layout="1-column-left">
        {yearData.months.map((monthData, monthIndex) => (
          <MonthSection key={monthIndex} monthData={monthData} iconStyle={yearData.iconStyle} />
        ))}
      </VerticalTimeline>
    </div>
  )

  const MonthSection = ({ monthData, iconStyle }) => (
    <div>
      {monthData.events &&
        monthData.events.map((event, index) => (
          <VerticalTimelineElement
            key={index}
            iconStyle={iconStyle}
            contentStyle={{
              background: "rgba(255, 255, 255, 0.95)",
              border: "1px solid #ddd",
              borderRadius: "20px",
              padding: "20px 30px",
            }}
            contentArrowStyle={{ borderRight: "none" }}
          >
            <div className="vertical-timeline-month-title">{monthData.month}</div>
            {renderEventMessage(event)}
          </VerticalTimelineElement>
        ))}
    </div>
  )

  if (timelineData.length === 0) {
    return <div>Loading...</div>
  }

  return (
    <div id="timeline" className="vertical-timeline-page">
      <TimelineHeader />
      <ParticlesBg type="cobweb" bg={true} color="#6495ED" />
      <div className="vertical-timelineContainer">
        {timelineData.map((yearData, yearIndex) => (
          <YearSection key={yearIndex} yearData={yearData} yearIndex={yearIndex} />
        ))}
      </div>
    </div>
  )
}

export default Timeline
