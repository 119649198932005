import React, { Component } from "react"
import Slide from "react-awesome-reveal"

class Work extends Component {
  render() {
    if (!this.props.data) return null

    const pride = this.props.data.pride.map(function (pride) {
      return (
        <div key={pride.team} className="prideContainer">
          <h3>{pride.team}</h3>
          {
            pride.events.map(event => {
              return (
                <p>
                  <p className="info">
                    <strong>{event.desc.rank}</strong> in the <em>{event.desc.project}</em> at the <u>{event.desc.competition}</u>.{" "}
                    <em className="date">{event.date}</em>
                  </p>
                  {
                    event.image ? (<img src={event.image} alt="pride" />) : ""
                  }
                </p>
              )
            })
          }



        </div>
      )
    })

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Our Pride</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{pride}</div>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    )
  }
}

export default Work
